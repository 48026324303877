import { XMLParser } from "fast-xml-parser";
import type { LabwareObject } from "../labware/labwareManager";
import { UnitConversionService } from "../utils/unitConversionService";
// Types for validation rules
type BaseRule = {
  required: boolean;
};

type VolumeRule = BaseRule & {
  type: "volume";
  min: number;
  max: number;
  unit: string;
};

type RangeRule = BaseRule & {
  type: "range";
  min: number;
  max: number;
  unit?: string;
};

type FloatRule = BaseRule & {
  type: "float";
  precision?: number;
};

type TextRule = BaseRule & {
  type: "text";
  pattern?: string;
};

type SelectRule = BaseRule & {
  type: "select";
  options: string[];
};

type DurationRule = BaseRule & {
  type: "duration";
  min: number;
  max: number;
  unit: string;
};

type TemperatureRule = BaseRule & {
  type: "temperature";
  min: number;
  max: number;
  unit: string;
};

type PlateRule = BaseRule & {
  type: "plate";
  options: string[];
};

// Interface for validation context
interface ValidationContext {
  document?: any; // The document containing labware metadata
}

// New rule type for labware validation
type LabwareRule = BaseRule & {
  type: "labware";
  allowedTypes: string[];
};

type ValidationRule =
  | VolumeRule
  | RangeRule
  | TextRule
  | SelectRule
  | DurationRule
  | TemperatureRule
  | PlateRule
  | LabwareRule
  | FloatRule;

type StepValidation = {
  name: string;
  params: Record<string, ValidationRule>;
};

interface ValidationParameter {
  name: string;
  value: string;
  position: number;
  namePosition: number;
  valuePosition: number;
}

interface ValidationStep {
  id: string;
  name: string;
  position: number;
  parameters: ValidationParameter[];
}

type ValidationResult = {
  isValid: boolean;
  errors: Array<{
    id: string;
    path: string;
    message: string;
    position: number;
    stepId?: string;
    context?: {
      stepData?: any;
      errorType?: string;
    };
  }>;
};

export class ValidationService {
  private stepRules: Map<string, StepValidation>;
  private xmlParser: XMLParser;
  private unitService: UnitConversionService;

  constructor() {
    this.stepRules = new Map();
    this.xmlParser = new XMLParser({
      ignoreAttributes: false,
      attributeNamePrefix: "",
    });
    this.unitService = new UnitConversionService();
  }

  private generateErrorId(): string {
    return crypto.randomUUID();
  }

  loadConfig(xmlConfig: string): void {
    try {
      // First validate that it's a proper XML string
      if (
        !xmlConfig.trim().startsWith("<?xml") ||
        !xmlConfig.includes("validation-rules-schema")
      ) {
        throw new Error(
          "Invalid XML format: Must be a valid XML document with validation-rules-schema root element"
        );
      }

      const config = this.xmlParser.parse(xmlConfig);
      this.stepRules.clear();

      // Get steps from config
      const steps = config["validation-rules-schema"]?.steps?.step || [];
      const stepsArray = Array.isArray(steps) ? steps : [steps];

      // Process each step
      for (const step of stepsArray) {
        const params = Array.isArray(step.param) ? step.param : [step.param];
        const paramRules: Record<string, ValidationRule> = {};

        // Process each parameter
        for (const param of params) {
          paramRules[param.name.toLowerCase()] =
            this.createValidationRule(param);
        }

        // Store step name in lowercase for case-insensitive matching
        this.stepRules.set(step.name.toLowerCase(), {
          name: step.name,
          params: paramRules,
        });
      }
    } catch (error: unknown) {
      throw new Error(
        `Failed to parse validation config: ${
          error instanceof Error ? error.message : String(error)
        }`
      );
    }
  }

  private createValidationRule(param: any): ValidationRule {
    const baseRule = {
      required: param.required === "true" || param.required === true,
    };

    switch (param.type) {
      case "volume":
        return {
          ...baseRule,
          type: "volume",
          min: Number(param.min),
          max: Number(param.max),
          unit: param.unit || "µL",
        };

      case "range":
        return {
          ...baseRule,
          type: "range",
          min: Number(param.min),
          max: Number(param.max),
          unit: param.unit,
        };

      case "float":
        return {
          ...baseRule,
          type: "float",
          precision: param.precision ? Number(param.precision) : undefined,
        };

      case "text":
        return {
          ...baseRule,
          type: "text",
          pattern: param.pattern,
        };

      case "select":
        return {
          ...baseRule,
          type: "select",
          options: Array.isArray(param.options?.option)
            ? param.options.option
            : [param.options?.option],
        };

      case "duration":
        return {
          ...baseRule,
          type: "duration",
          min: Number(param.min),
          max: Number(param.max),
          unit: param.unit || "min",
        };

      case "temperature":
        return {
          ...baseRule,
          type: "temperature",
          min: Number(param.min),
          max: Number(param.max),
          unit: param.unit || "°C",
        };

      case "plate":
        return {
          ...baseRule,
          type: "plate",
          options: Array.isArray(param.options?.option)
            ? param.options.option
            : [param.options?.option],
        };

      case "labware":
        return {
          ...baseRule,
          type: "labware",
          allowedTypes: Array.isArray(param.options?.option)
            ? param.options.option
            : [param.options?.option],
        };

      default:
        throw new Error(`Unknown parameter type: ${param.type}`);
    }
  }

  validateStep(
    step: ValidationStep,
    context: ValidationContext = {}
  ): ValidationResult {
    // If step has no name, treat it as valid
    if (!step.name || step.name.trim() === "") {
      return {
        isValid: true,
        errors: [],
      };
    }

    // Convert step name to lowercase for case-insensitive matching
    const stepNameLowerCase = step.name.toLowerCase();
    const rules = this.stepRules.get(stepNameLowerCase);
    if (!rules) {
      return {
        isValid: false,
        errors: [
          {
            id: this.generateErrorId(),
            path: `/${step.name}`,
            message: `Unknown step type: ${step.name}`,
            position: step.position,
            stepId: step.id,
            context: {
              errorType: "unknown_step",
              stepData: { name: step.name },
            },
          },
        ],
      };
    }

    const errors: ValidationResult["errors"] = [];
    const validParamNames = new Set(Object.keys(rules.params));

    // Check for duplicate parameters
    const paramNameCounts = new Map<string, ValidationParameter[]>();
    for (const param of step.parameters) {
      const normalizedName = param.name.toLowerCase();
      if (!paramNameCounts.has(normalizedName)) {
        paramNameCounts.set(normalizedName, []);
      }
      paramNameCounts.get(normalizedName)!.push(param);
    }

    // Add errors for duplicate parameters
    for (const [normalizedName, params] of paramNameCounts.entries()) {
      if (params.length > 1) {
        // Add errors for all duplicates after the first occurrence
        for (let i = 1; i < params.length; i++) {
          errors.push({
            id: this.generateErrorId(),
            path: `/${step.name}/${params[i].name}`,
            message: `Duplicate parameter: ${params[i].name}`,
            position: params[i].namePosition,
            stepId: step.id,
            context: {
              errorType: "duplicate_parameter",
              stepData: {
                name: step.name,
                parameter: params[i].name,
              },
            },
          });
        }
      }
    }

    // Check for unknown parameters
    for (const param of step.parameters) {
      if (!validParamNames.has(param.name.toLowerCase())) {
        errors.push({
          id: this.generateErrorId(),
          path: param.name,
          message: `Unknown parameter: ${param.name}`,
          position: param.namePosition,
          stepId: step.id,
          context: {
            errorType: "unknown_parameter",
            stepData: {
              name: step.name,
              parameter: param.name,
            },
          },
        });
      }
    }

    // Check required params and validate values
    for (const [paramName, rule] of Object.entries(rules.params)) {
      const param = step.parameters.find(
        (p) => p.name.toLowerCase() === paramName.toLowerCase()
      );
      const path = `/${step.name}/${paramName}`;

      // Check required parameters
      if (rule.required && !param) {
        errors.push({
          id: this.generateErrorId(),
          path,
          message: `Missing required parameter: ${paramName}`,
          position: step.position,
          stepId: step.id,
          context: {
            errorType: "missing_required_parameter",
            stepData: {
              name: step.name,
              parameter: paramName,
              rule,
            },
          },
        });
        continue;
      }

      // Skip validation for undefined optional parameters
      if (!param) continue;

      // Validate based on type
      const validationError = this.validateValue(
        param,
        rule,
        path,
        step,
        context
      );
      if (validationError) {
        errors.push({
          ...validationError,
          id: this.generateErrorId(),
          position: param.valuePosition,
          stepId: step.id,
        });
      }
    }

    return {
      isValid: errors.length === 0,
      errors,
    };
  }

  private validateValue(
    param: ValidationParameter,
    rule: ValidationRule,
    path: string,
    step: ValidationStep,
    context: ValidationContext = {}
  ): Omit<
    ValidationResult["errors"][number],
    "position" | "id" | "stepId"
  > | null {
    const value = param.value;

    switch (rule.type) {
      case "volume":
      case "range":
      case "duration":
      case "temperature": {
        // Use the UnitConversionService for validation
        const validationResult = this.unitService.validateValueWithUnit(
          value,
          rule.type,
          rule.min,
          rule.max,
          rule.unit as string
        );

        if (!validationResult.isValid) {
          return {
            path,
            message:
              validationResult.errorMessage ||
              `Invalid value for ${param.name}`,
            context: {
              errorType: validationResult.errorMessage?.includes("Invalid unit")
                ? "invalid_unit"
                : "out_of_range",
              stepData: {
                name: step.name,
                parameter: param.name,
                value,
                rule,
                convertedValue: validationResult.convertedValue,
                unit: validationResult.unit,
              },
            },
          };
        }
        break;
      }

      case "float": {
        // Validate float value
        const numValue = parseFloat(value);

        if (isNaN(numValue)) {
          return {
            path,
            message: `${param.name} must be a valid number`,
            context: {
              errorType: "invalid_number",
              stepData: {
                name: step.name,
                parameter: param.name,
                value,
                rule,
              },
            },
          };
        }

        break;
      }

      case "text":
        if (rule.pattern && !new RegExp(rule.pattern).test(value)) {
          return {
            path,
            message: `${path} does not match required pattern`,
            context: {
              errorType: "invalid_pattern",
              stepData: {
                name: step.name,
                parameter: param.name,
                value,
                rule,
              },
            },
          };
        }
        break;

      case "select":
      case "plate":
        if (!rule.options.includes(value)) {
          return {
            path,
            message: `${param.name} must be one of: ${rule.options.join(", ")}`,
            context: {
              errorType: "invalid_option",
              stepData: {
                name: step.name,
                parameter: param.name,
                value,
                rule,
              },
            },
          };
        }
        break;

      case "labware": {
        // Check if we have a document in the context
        if (!context.document) {
          console.warn("Document context not provided for labware validation");
          break;
        }

        // Get labware options from document
        const docAttrs = context.document.attrs || {};
        const selectOptions = docAttrs.selectOptions || {};
        const allLabware = selectOptions.labware || ([] as LabwareObject[]);

        // Check if the labware is provided
        if (!value || value.trim() === "") {
          return {
            path,
            message: `Missing required labware parameter: "${param.name}"`,
            context: {
              errorType: "missing_required_labware",
              stepData: {
                name: step.name,
                parameter: param.name,
                rule,
              },
            },
          };
        }

        // Find the labware in the document metadata
        const labware = allLabware.find((l: LabwareObject) => l.id === value);

        if (!labware) {
          return {
            path,
            message: `${param.name} references unknown labware ID: ${value}`,
            context: {
              errorType: "unknown_labware",
              stepData: {
                name: step.name,
                parameter: param.name,
                value,
                rule,
              },
            },
          };
        }
        // Validate the labware type
        if (!rule.allowedTypes.includes(labware.typeId)) {
          return {
            path,
            message: `${
              param.name
            } must use labware of type: ${rule.allowedTypes.join(", ")}`,
            context: {
              errorType: "invalid_labware_type",
              stepData: {
                name: step.name,
                parameter: param.name,
                value,
                labwareType: labware.typeId,
                rule,
              },
            },
          };
        }
        break;
      }
    }

    return null;
  }

  validateSteps(
    steps: ValidationStep[],
    context: ValidationContext = {}
  ): ValidationResult {
    const errors: ValidationResult["errors"] = [];

    for (const [index, step] of steps.entries()) {
      const result = this.validateStep(step, context);
      if (!result.isValid) {
        errors.push(
          ...result.errors.map((error) => ({
            ...error,
            id: this.generateErrorId(),
            path: `/steps/${index}${error.path}`,
            stepId: step.id,
          }))
        );
      }
    }

    return {
      isValid: errors.length === 0,
      errors,
    };
  }
}
