import { ref } from "vue";
import type { Editor } from "@tiptap/vue-3";
import type { LabwareObject } from "@/automation/labware/labwareManager";

const activeMethodEditor = ref<Editor | null>(null);

/**
 * This is the global state store for managing the active method editor.
 */
export function useMethodState() {
  function setActiveMethodEditor(editor: Editor | null) {
    //@ts-ignore
    activeMethodEditor.value = editor;
  }

  /**
   * Retrieves all labware objects from the document
   * @returns Array of labware objects or empty array if no labware or editor is found
   */
  function getDocumentLabware(): LabwareObject[] {
    if (!activeMethodEditor.value) return [];

    const doc = activeMethodEditor.value.state.doc;
    const selectOptions = doc.attrs.selectOptions || {};
    const labware = (selectOptions.labware || []) as LabwareObject[];

    return labware;
  }

  /**
   * Retrieves only labware objects that are referenced in select value blocks
   * @returns Array of labware objects or empty array if no labware or editor is found
   */
  function getReferencedLabware(): LabwareObject[] {
    if (!activeMethodEditor.value) return [];

    const allLabware = getDocumentLabware();

    // Track referenced labware IDs
    const referencedIds = new Set<string>();

    // Find all selectValue nodes that reference labware
    activeMethodEditor.value.state.doc.descendants((node) => {
      if (node.type.name === "selectValue" && node.attrs.selectedId) {
        referencedIds.add(node.attrs.selectedId);
      }
    });

    // Filter labware to only include referenced ones
    return allLabware.filter((labware) => referencedIds.has(labware.id));
  }

  return {
    activeMethodEditor,
    setActiveMethodEditor,
    getDocumentLabware,
    getReferencedLabware,
  };
}
