import { Transaction } from "@tiptap/pm/state";
import { Node as PMNode } from "@tiptap/pm/model";

interface Parameter {
  uid: string;
  name: string;
  value: string;
  position: number;
  namePosition: number;
  valuePosition: number;
}

interface SerializedStep {
  id: string;
  position: number;
  name: string;
  parameters: Parameter[];
}

interface StepDiffOptions {
  suggestionId: string;
  oldStep: SerializedStep;
  newStep: SerializedStep;
  tr: Transaction;
}

function findParameterPositions(doc: PMNode, stepPos: number) {
  const positions = new Map<
    string, // Parameter UID
    {
      pos: number;
      namePos: number;
      valuePos: number;
    }
  >();

  // Get step node and resolve its position
  const stepNode = doc.nodeAt(stepPos);
  if (!stepNode) return positions;

  // Find parameters node within the step
  let parametersPos = -1;

  // Use a more reliable way to find the parameters node
  stepNode.forEach((node, offset) => {
    if (node.type.name === "parameters") {
      // Calculate absolute position: stepPos + 1 (to enter the step node) + offset
      parametersPos = stepPos + 1 + offset;
    }
  });

  if (parametersPos === -1) {
    console.error("Parameters node not found within step");
    return positions;
  }

  // Get the parameters node
  const parametersNode = doc.nodeAt(parametersPos);
  if (!parametersNode) {
    console.error(
      "Could not access parameters node at position:",
      parametersPos
    );
    return positions;
  }

  // Process each parameter directly from the parameters node
  parametersNode.forEach((paramNode, paramOffset) => {
    if (paramNode.type.name !== "parameter") return;

    const paramUid = paramNode.attrs.uid || "";
    if (!paramUid) return;

    // Calculate absolute position for this parameter
    // parametersPos + 1 (to enter parameters node) + paramOffset
    const paramPos = parametersPos + 1 + paramOffset;

    let namePos = -1;
    let valuePos = -1;

    // Find name and value positions within this parameter
    paramNode.forEach((child, childOffset) => {
      // Calculate absolute position: paramPos + 1 (to enter param node) + childOffset
      const childPos = paramPos + 1 + childOffset;

      if (child.type.name === "paramName") {
        namePos = childPos;
      }
      if (child.type.name === "paramValue") {
        valuePos = childPos;
      }
    });

    positions.set(paramUid, {
      pos: paramPos,
      namePos,
      valuePos,
    });
  });

  return positions;
}

/**
 * Creates a diff between two steps and applies the necessary Prosemirror transactions
 * to transform one into the other using the suggestions system.
 *
 * All changes will be grouped under a single suggestionId for atomic accept/reject.
 *
 * @param options.oldStep - The original step from the document
 * @param options.newStep - The new step to transform into
 * @param options.suggestionId - Unique ID to group all changes under
 * @param options.tr - The transaction to apply changes to
 * @returns The modified transaction
 */
export function createStepDiff({
  oldStep,
  newStep,
  suggestionId,
  tr,
}: StepDiffOptions): Transaction {
  // Helper function to get fresh positions after each change
  const getUpdatedPositions = () =>
    findParameterPositions(tr.doc, oldStep.position);

  // 1. Handle step name change first (position is reliable)
  if (oldStep.name !== newStep.name) {
    // Resolve the step position to get more context
    const $stepPos = tr.doc.resolve(oldStep.position);
    const stepNode = $stepPos.nodeAfter;

    if (!stepNode || stepNode.type.name !== "step") {
      console.error("Could not find step node at position:", oldStep.position);
      return tr;
    }

    // Find the stepName node position
    let stepNamePos = -1;
    stepNode.forEach((node, offset) => {
      if (node.type.name === "stepName") {
        // Calculate absolute position: step position + 1 (to enter step) + offset
        stepNamePos = oldStep.position + 1 + offset;
      }
    });

    if (stepNamePos === -1) {
      console.error("Could not find stepName node within step");
      return tr;
    }

    const stepNameNode = tr.doc.nodeAt(stepNamePos);
    if (!stepNameNode) {
      console.error("Could not access stepName node at position:", stepNamePos);
      return tr;
    }

    // Get the schema from the transaction's document
    const schema = tr.doc.type.schema;

    // Create new stepName node with proper attributes
    const newStepName = schema.nodes.stepName.create(
      {
        suggest: "replace",
        suggestionId,
        original: oldStep.name,
      },
      schema.text(newStep.name)
    );

    // Use a single replaceWith operation like the working debug implementation
    tr.replaceWith(
      stepNamePos,
      stepNamePos + stepNameNode.nodeSize,
      newStepName
    );

    return tr;
  }

  // 2. Handle parameter name changes
  const oldParams = new Map(oldStep.parameters.map((p) => [p.uid, p]));
  const newParams = new Map(newStep.parameters.map((p) => [p.uid, p]));

  // Process name changes one at a time, recalculating positions after each change
  for (const [uid, newParam] of newParams) {
    const oldParam = oldParams.get(uid);
    if (oldParam && oldParam.name !== newParam.name) {
      // Get fresh positions for this change
      const paramPositions = getUpdatedPositions();

      const paramPos = paramPositions.get(uid);
      if (!paramPos) {
        console.error("Could not find parameter positions for uid:", uid);
        continue;
      }

      // Get the paramName node directly
      const namePos = paramPos.namePos;
      const $namePos = tr.doc.resolve(namePos);
      const nameNode = $namePos.nodeAfter;

      if (!nameNode || nameNode.type.name !== "paramName") {
        console.error("Could not find paramName node at position:", namePos);
        continue;
      }

      // Get schema from transaction
      const schema = tr.doc.type.schema;

      // Create new paramName node with updated text, preserving existing attributes
      const newParamName = schema.nodes.paramName.create(
        {
          ...nameNode.attrs,
          suggest: "replace",
          suggestionId,
          original: oldParam.name,
        },
        schema.text(newParam.name)
      );

      tr.replaceWith(namePos, namePos + nameNode.nodeSize, newParamName);
    }
  }

  // 3. Handle parameter value changes
  // Process value changes one at a time, recalculating positions after each change
  for (const [uid, newParam] of newParams) {
    const oldParam = oldParams.get(uid);
    if (oldParam && oldParam.value !== newParam.value) {
      // Get fresh positions for this change
      const paramPositions = getUpdatedPositions();

      const paramPos = paramPositions.get(uid);
      if (!paramPos) {
        console.error("Could not find parameter positions for uid:", uid);
        continue;
      }

      // Get the paramValue node directly
      const valuePos = paramPos.valuePos;
      const $valuePos = tr.doc.resolve(valuePos);
      const valueNode = $valuePos.nodeAfter;

      if (!valueNode || valueNode.type.name !== "paramValue") {
        console.error("Could not find paramValue node at position:", valuePos);
        continue;
      }

      // Get schema from transaction
      const schema = tr.doc.type.schema;

      // Determine if this is a labware parameter by checking if it exists in document metadata
      // Get document labware metadata
      const docAttrs = tr.doc.attrs || {};
      const selectOptions = docAttrs.selectOptions || {};
      const labwareList = selectOptions.labware || [];

      // Check if the parameter value corresponds to a labware ID
      const isLabware = labwareList.some(
        (labware: any) => labware.id === newParam.value
      );

      // Create content based on whether it's labware or regular text
      let content;
      if (isLabware) {
        // For labware, create a selectValue node
        content = schema.nodes.selectValue.create(
          { optionsKey: "labware", selectedId: newParam.value },
          []
        );
      } else {
        // For regular values, create a text node
        content = schema.text(newParam.value);
      }

      // Create new paramValue node with updated content, preserving existing attributes
      const newParamValue = schema.nodes.paramValue.create(
        {
          ...valueNode.attrs,
          suggest: "replace",
          suggestionId,
          original: oldParam.value,
        },
        content
      );

      tr.replaceWith(valuePos, valuePos + valueNode.nodeSize, newParamValue);
    }
  }

  // 4. Handle removed parameters
  // Process removals one at a time, recalculating positions after each change
  for (const [uid, oldParam] of oldParams) {
    if (!newParams.has(uid)) {
      // Get fresh positions for this change
      const paramPositions = getUpdatedPositions();

      const paramPos = paramPositions.get(uid);
      if (!paramPos) {
        console.error("Could not find parameter positions for uid:", uid);
        continue;
      }

      const $pos = tr.doc.resolve(paramPos.pos);
      const paramNode = $pos.nodeAfter;

      if (!paramNode || paramNode.type.name !== "parameter") {
        console.error(
          "Could not find parameter node at position:",
          paramPos.pos,
          "Found type:",
          paramNode?.type.name,
          "with attrs:",
          paramNode?.attrs
        );
        continue;
      }

      const newAttrs = {
        ...paramNode.attrs,
        suggest: "remove",
        suggestionId,
      };

      tr.setNodeMarkup(paramPos.pos, undefined, newAttrs);
    }
  }

  // 5. Handle added parameters
  // Find parameters node position using fresh document state
  let parametersPos = -1;
  const stepNode = tr.doc.nodeAt(oldStep.position);

  if (stepNode) {
    stepNode.forEach((node, offset) => {
      if (node.type.name === "parameters") {
        parametersPos = oldStep.position + 1 + offset;
      }
    });
  } else {
    console.error("Step node not found at position:", oldStep.position);
  }

  if (parametersPos === -1) {
    console.error("Could not find parameters node within step");
    return tr;
  }

  const parametersNode = tr.doc.nodeAt(parametersPos);

  if (!parametersNode) {
    console.error(
      "Could not access parameters node at position:",
      parametersPos
    );
    return tr;
  }

  // Process additions one at a time
  for (const [uid, newParam] of newParams) {
    if (!oldParams.has(uid)) {
      // Get fresh positions for this change
      const parametersNode = tr.doc.nodeAt(parametersPos);
      if (!parametersNode) {
        console.error(
          "Could not access parameters node at position:",
          parametersPos
        );
        continue;
      }

      // Calculate the current insert position based on the current state
      const insertPos = parametersPos + 1 + parametersNode.content.size;

      // Get schema from transaction
      const schema = tr.doc.type.schema;

      // Determine if this is a labware parameter by checking if it exists in document metadata
      // Get document labware metadata
      const docAttrs = tr.doc.attrs || {};
      const selectOptions = docAttrs.selectOptions || {};
      const labwareList = selectOptions.labware || [];

      // Check if the parameter value corresponds to a labware ID
      const isLabware = labwareList.some(
        (labware: any) => labware.id === newParam.value
      );

      // Create parameter name and value nodes
      const paramName = schema.nodes.paramName.create({}, [
        schema.text(newParam.name),
      ]);

      // Create value content based on whether it's labware or regular text
      let valueContent;
      if (isLabware) {
        // For labware, create a selectValue node
        valueContent = schema.nodes.selectValue.create(
          { optionsKey: "labware", selectedId: newParam.value },
          []
        );
      } else {
        // For regular values, create a text node
        valueContent = schema.text(newParam.value);
      }

      const paramValue = schema.nodes.paramValue.create({}, valueContent);

      // Create the new parameter node
      const paramNode = schema.nodes.parameter.create(
        { suggest: "add", suggestionId, uid },
        [paramName, paramValue]
      );

      tr.insert(insertPos, paramNode);
    }
  }

  return tr;
}
