import type {
  DeckLayoutDefinition,
  DeckConfiguration,
} from "@/state/deckLayoutState";

/**
 * Default deck layout definitions
 */
export const defaultDeckLayouts: DeckLayoutDefinition[] = [
  {
    id: "standard-dual-deck",
    label: "Standard Dual Deck",
    positions: [
      // Top deck positions
      { id: "1" },
      { id: "2" },
      { id: "3" },
      { id: "4" },
      { id: "5" },
      { id: "6" },
      // Bottom deck positions
      { id: "7" },
      { id: "8" },
      { id: "9" },
      { id: "10" },
      { id: "11" },
      { id: "12" },
    ],
    sections: [
      {
        id: "top-deck",
        label: "Top Deck",
        positionIds: ["7", "8", "9", "10", "11", "12"],
      },
      {
        id: "bottom-deck",
        label: "Bottom  Deck",
        positionIds: ["1", "2", "3", "4", "5", "6"],
      },
    ],
  },
];

/**
 * Converts a deck layout definition to a deck configuration
 *
 * @param layoutId The ID of the deck layout definition
 * @returns Deck configuration for the given layout, or null if no matching layout
 */
export function createDeckConfigurationFromLayout(
  layoutId: string
): DeckConfiguration | null {
  const layout = defaultDeckLayouts.find((l) => l.id === layoutId);

  if (!layout) return null;

  // Create a copy of the layout with isVerified property added to each position
  const updatedPositions = layout.positions.map((pos) => ({
    ...pos,
    isVerified: false,
  }));

  return {
    id: layout.id,
    label: layout.label,
    positions: updatedPositions,
    sections: layout.sections,
  };
}
