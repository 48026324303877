import * as math from "mathjs";

// Define unit categories for validation
export type UnitCategory =
  | "volume"
  | "mass"
  | "time"
  | "temperature"
  | "length"
  | "speed"
  | "rotation";

// Mapping unit types to categories
export interface UnitCategoryMap {
  volume: string[]; // e.g., ["µL", "mL", "L"]
  mass: string[]; // e.g., ["µg", "mg", "g", "kg"]
  time: string[]; // e.g., ["s", "min", "h"]
  temperature: string[]; // e.g., ["°C", "K", "°F"]
  length: string[]; // e.g., ["nm", "µm", "mm", "cm", "m"]
  speed: string[]; // e.g., ["µL/s", "mL/min"]
  rotation: string[]; // e.g., ["RPM", "Hz"]
}

// Map parameter types to unit categories
export const paramTypeToUnitCategory: Record<string, UnitCategory> = {
  volume: "volume",
  duration: "time",
  temperature: "temperature",
  // Range type could be any category depending on its usage
};

export class UnitConversionService {
  private unitCategories: UnitCategoryMap;
  private unitAliases: Map<string, string>;

  constructor() {
    // Initialize unit aliases
    this.unitAliases = new Map([
      // Volume aliases
      ["ul", "uL"],
      ["µL", "uL"],
      ["ml", "mL"],
      ["l", "L"],

      // Temperature aliases
      ["C", "degC"],
      ["°C", "degC"],
      ["°F", "degF"],
      ["F", "degF"],
    ]);

    // Initialize unit categories with their valid units
    this.unitCategories = {
      volume: ["uL", "mL", "L"],
      mass: ["ug", "mg", "g", "kg"],
      time: ["s", "min", "h"],
      temperature: ["degC", "K", "degF"],
      length: ["nm", "um", "mm", "cm", "m"],
      speed: ["uL/s", "mL/min"],
      rotation: ["RPM", "Hz"],
    };

    // Ensure math.js recognizes our units
    try {
      // Volume units
      math.createUnit("uL", "1e-6 L", { override: true });

      // Temperature units with proper conversion definitions
      math.createUnit("K", undefined, { override: true }); // Base unit for temperature
      math.createUnit(
        "degC",
        { definition: "K", offset: 273.15 },
        { override: true }
      );
      math.createUnit(
        "degF",
        { definition: "0.555556 K", offset: 255.372 },
        { override: true }
      );
    } catch (e) {
      // Units might already exist, which is fine
      console.warn("Some units might already exist:", e);
    }
  }

  // Helper method to normalize unit strings
  private normalizeUnit(unit: string): string {
    return this.unitAliases.get(unit) || unit;
  }

  // Helper method to denormalize unit for display
  private denormalizeUnit(unit: string): string {
    switch (unit) {
      case "degC":
        return "°C";
      case "degF":
        return "°F";
      case "uL":
        return "µL";
      default:
        return unit;
    }
  }

  /**
   * Get the unit category for a given unit
   * @param unit The unit to check
   * @returns The category the unit belongs to, or null if not found
   */
  public getCategoryForUnit(unit: string): UnitCategory | null {
    const normalizedUnit = this.normalizeUnit(unit);
    for (const [category, units] of Object.entries(this.unitCategories)) {
      if (units.includes(normalizedUnit)) {
        return category as UnitCategory;
      }
    }
    return null;
  }

  /**
   * Check if a unit is valid for a given category
   * @param unit The unit to check
   * @param category The category to check against
   * @returns Whether the unit is valid for the category
   */
  public isValidUnitForCategory(unit: string, category: UnitCategory): boolean {
    const normalizedUnit = this.normalizeUnit(unit);
    return this.unitCategories[category]?.includes(normalizedUnit) || false;
  }

  /**
   * Get valid units for a category
   * @param category The category to get units for
   * @returns Array of valid units for the category, in display format
   */
  public getValidUnitsForCategory(category: UnitCategory): string[] {
    return (this.unitCategories[category] || []).map((unit) =>
      this.denormalizeUnit(unit)
    );
  }

  /**
   * Get a user-friendly display of valid units for a category
   * @param category The unit category
   * @returns A formatted string of valid units
   */
  public getValidUnitsDisplay(category: UnitCategory): string {
    return this.getValidUnitsForCategory(category).join(", ");
  }

  /**
   * Parse a string value that might include a unit
   * Example: "100 µL" -> { value: 100, unit: "uL" }
   * @param input The input string to parse
   * @returns Object containing the numeric value and unit (if found)
   */
  public parseValueWithUnit(input: string): {
    value: number;
    unit: string | null;
  } {
    // Try to extract value and unit using regex
    // This regex matches a number followed by optional whitespace and then letters/symbols
    const match = input.trim().match(/^([+-]?\d*\.?\d+)\s*([^\s\d][^\s]*)$/);

    if (match) {
      const unit = this.normalizeUnit(match[2]);
      return {
        value: parseFloat(match[1]),
        unit,
      };
    }

    // If no unit found, just return the parsed number
    return {
      value: parseFloat(input),
      unit: null,
    };
  }

  /**
   * Convert a value from one unit to another
   * @param value The numeric value to convert
   * @param fromUnit The source unit
   * @param toUnit The target unit
   * @returns The converted value
   * @throws Error if units are incompatible or invalid
   */
  public convert(value: number, fromUnit: string, toUnit: string): number {
    try {
      // Normalize units before conversion
      const normalizedFromUnit = this.normalizeUnit(fromUnit);
      const normalizedToUnit = this.normalizeUnit(toUnit);

      // If units are the same, no conversion needed
      if (normalizedFromUnit === normalizedToUnit) {
        return value;
      }

      // Create math.js units for conversion
      const fromUnitObj = math.unit(value, normalizedFromUnit);
      const converted = fromUnitObj.to(normalizedToUnit);

      // Extract the numeric value
      return converted.toNumber(normalizedToUnit);
    } catch (error) {
      throw new Error(
        `Cannot convert from ${fromUnit} to ${toUnit}: ${
          error instanceof Error ? error.message : String(error)
        }`
      );
    }
  }

  /**
   * Get default unit for a parameter type
   * @param paramType The type of parameter
   * @returns The default unit for that parameter type
   */
  public getDefaultUnitForParamType(paramType: string): string {
    switch (paramType) {
      case "volume":
        return "µL";
      case "duration":
        return "min";
      case "temperature":
        return "°C";
      default:
        return "";
    }
  }

  /**
   * Get the unit category for a parameter type
   * @param paramType The parameter type
   * @returns The corresponding unit category
   */
  public getCategoryForParamType(paramType: string): UnitCategory | null {
    return paramTypeToUnitCategory[paramType] || null;
  }

  /**
   * Validate a value with a unit against a parameter type and range
   * @param value The raw value (possibly with unit)
   * @param paramType The parameter type
   * @param min The minimum allowed value (in default unit)
   * @param max The maximum allowed value (in default unit)
   * @param defaultUnit The default unit to use if none provided
   * @returns Object with validation results
   */
  public validateValueWithUnit(
    value: string,
    paramType: string,
    min: number,
    max: number,
    defaultUnit: string
  ): {
    isValid: boolean;
    convertedValue?: number;
    errorMessage?: string;
    unit: string;
  } {
    // Parse the value and unit
    const { value: numValue, unit } = this.parseValueWithUnit(value);

    // If parsing failed, return error
    if (isNaN(numValue)) {
      return {
        isValid: false,
        errorMessage: `Invalid number format: ${value}`,
        unit: this.normalizeUnit(defaultUnit),
      };
    }

    // Determine unit to use (provided or default)
    const effectiveUnit = unit || this.normalizeUnit(defaultUnit);
    const normalizedUnit = this.normalizeUnit(effectiveUnit);

    // Get the expected category for this parameter type
    const expectedCategory = this.getCategoryForParamType(paramType);

    if (!expectedCategory) {
      // If parameter type has no category (like text), just validate the number
      return {
        isValid: numValue >= min && numValue <= max,
        convertedValue: numValue,
        errorMessage:
          numValue < min || numValue > max
            ? `Value must be between ${min} and ${max}${
                defaultUnit
                  ? ` ${this.denormalizeUnit(this.normalizeUnit(defaultUnit))}`
                  : ""
              }`
            : undefined,
        unit: this.normalizeUnit(defaultUnit),
      };
    }

    // Check if the unit is valid for the category
    if (!this.isValidUnitForCategory(effectiveUnit, expectedCategory)) {
      return {
        isValid: false,
        errorMessage: `Invalid unit '${this.denormalizeUnit(
          effectiveUnit
        )}' for ${paramType}. Expected a ${expectedCategory} unit (${this.getValidUnitsDisplay(
          expectedCategory
        )})`,
        unit: this.normalizeUnit(defaultUnit),
      };
    }

    try {
      // Convert to default unit for range validation
      const normalizedDefaultUnit = this.normalizeUnit(defaultUnit);
      const convertedValue = Math.round(
        this.convert(numValue, normalizedUnit, normalizedDefaultUnit)
      );

      // Check if within range after conversion
      if (convertedValue < min || convertedValue > max) {
        let errorMessage = `Value must be between ${min} and ${max} ${this.denormalizeUnit(
          normalizedDefaultUnit
        )}`;

        // If user provided a different unit, include conversion info
        if (unit && normalizedUnit !== normalizedDefaultUnit) {
          errorMessage += `. You entered ${numValue} ${this.denormalizeUnit(
            normalizedUnit
          )} (equivalent to ${convertedValue} ${this.denormalizeUnit(
            normalizedDefaultUnit
          )})`;

          if (convertedValue < min) {
            errorMessage += `, which is below the minimum`;
          } else {
            errorMessage += `, which exceeds the maximum`;
          }
        }

        return {
          isValid: false,
          convertedValue,
          errorMessage,
          unit: normalizedUnit,
        };
      }

      return {
        isValid: true,
        convertedValue,
        unit: normalizedUnit,
      };
    } catch (error) {
      // Handle conversion errors
      return {
        isValid: false,
        errorMessage: `Conversion error: ${
          error instanceof Error ? error.message : String(error)
        }`,
        unit: normalizedUnit,
      };
    }
  }
}
