import type { User } from "@firebase/auth";
import { auth, db, functions } from "./firebase";
import { httpsCallable } from "@firebase/functions";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  getAuth,
  signOut as firebaseSignOut,
  signInWithCustomToken,
} from "@firebase/auth";
import { deleteUser, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { onAuthStateChanged as firebaseOnAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { z } from "zod";
import { createClient } from "@liveblocks/client";
import { type CustomAuthenticationResult } from "@liveblocks/core";

const liveblocksAuth = httpsCallable<
  { room: string },
  CustomAuthenticationResult
>(functions, "liveblocksAuth");

export async function resendVerificationEmail(email: string) {
  console.log("Resending verification email to", email);
  const user = await signInWithEmailAndPassword(auth, email, "");
  if (user.user) {
    await sendEmailVerification(user.user);
  } else {
    throw new Error("User not found");
  }
}

export const liveblocksClient = createClient({
  authEndpoint: async (room) =>
    room
      ? (await liveblocksAuth({ room })).data
      : { error: "Can't create liveblocksAuth", reason: "No room specified" },
});

export async function signUp(email: string, password: string) {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    await sendEmailVerification(userCredential.user);
    return userCredential;
  } catch (error: any) {
    if (error.code === "auth/email-already-in-use") {
      throw new Error(
        "Email already in use. Please login or contact support at team@briefly.bio."
      );
    } else {
      throw new Error(
        "An unknown error occurred during sign up, contact support at team@briefly.bio"
      );
    }
  }
}

export async function signIn(email: string, password: string) {
  const auth = getAuth();
  const userCredential = await signInWithEmailAndPassword(
    auth,
    email,
    password
  );
  const user = userCredential.user;

  if (!user.emailVerified) {
    await sendEmailVerification(user);
    setTimeout(() => {
      signOut();
    }, 3000);
    throw new Error(
      "Your account is not verified. A verification email has been sent."
    );
  }
  return userCredential;
}

export async function signOut() {
  const currentPath = window.location.pathname;
  await firebaseSignOut(auth);
  window.location.href = `/login?redirect=${encodeURIComponent(currentPath)}`;
}

export function signOutSilent() {
  firebaseSignOut(auth);
}

export function onAuthStateChanged(callback: (user: User | null) => void) {
  return firebaseOnAuthStateChanged(auth, callback);
}

export async function signInWithGoogle() {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    const userDoc = await getDoc(doc(db, `users/${user.uid}`));
    if (!userDoc.exists()) {
      console.error(
        "User does not exist in the database, please sign up first."
      );
      await deleteUser(user);
      await auth.signOut();
      return;
    }
    return result;
  } catch (error) {
    console.error("Error during Google sign-in:", error);
    alert("An error occurred during sign-in. Please try again.");
  }
}

export const createWorkspaceSchema = (
  wording: "Username" | "Workspace" = "Workspace"
) =>
  z
    .string()
    .min(5, `${wording} must be at least 5 characters`)
    .max(100, `${wording} must be at most 100 characters`)
    .refine((value) => /^[A-Za-z0-9 _-]+$/.test(value), {
      message:
        "Only letters, numbers,hyphen (-), underscore (_) and spaces are allowed",
    })
    .transform((w) => w.toLowerCase().replace(/ /g, "-").replace(/_/g, "-"))
    .refine(workspaceAvailable, (val: string) => ({
      message: `${wording} ${val} is already taken`,
    }));

export const workspaceAvailable = async (username: string) => {
  if (!username) return false;
  const collectionRef = doc(db, `workspaces/${username}`);
  const docSnap = await getDoc(collectionRef);
  return !docSnap.exists();
};

export const userExists = async (uid: string) => {
  if (!uid) return false;
  const collectionRef = doc(db, `users/${uid}`);
  const docSnap = await getDoc(collectionRef);
  return docSnap.exists();
};

export async function fetchUserProfile(userId: string) {
  const userRef = doc(db, "users", userId);
  const userDoc = await getDoc(userRef);
  if (userDoc.exists()) {
    return userDoc.data();
  } else {
    throw new Error("User not found");
  }
}

/**
 * Start impersonating another user. Only available to super admins.
 * @param targetIdentifier - Either email or userId of the target user
 * @param existingToken - Optional token if already generated (used by URL-based impersonation)
 */
export async function startImpersonation(
  targetIdentifier: string,
  existingToken?: string
) {
  const adminUser = auth.currentUser;
  if (!adminUser) throw new Error("No user logged in");

  // Check if user is a super admin
  const userDoc = await getDoc(doc(db, "users", adminUser.uid));
  if (!userDoc.exists() || !userDoc.data().superAdmin) {
    throw new Error("Unauthorized: Only super admins can impersonate users");
  }

  let impersonationToken: string;

  if (existingToken) {
    impersonationToken = existingToken;
  } else {
    // Determine if targetIdentifier is an email
    const isEmail = targetIdentifier.includes("@");

    // Get target user's auth token
    const {
      data: { token },
    } = await httpsCallable<
      { targetUserId?: string; targetEmail?: string },
      { token: string }
    >(
      functions,
      "generateImpersonationToken"
    )(
      isEmail
        ? { targetEmail: targetIdentifier }
        : { targetUserId: targetIdentifier }
    );

    impersonationToken = token;
  }

  // Sign in with target user's token
  await signInWithCustomToken(auth, impersonationToken);

  // Store original admin ID in session storage
  sessionStorage.setItem("impersonator", adminUser.uid);
}

/**
 * Check if the current session is an impersonation
 */
export function isImpersonating(): boolean {
  return sessionStorage.getItem("impersonator") !== null;
}
