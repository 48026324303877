interface Parameter {
  uid: string;
  name: string;
  value: string;
  position: number;
  namePosition: number;
  valuePosition: number;
}

export interface SerializedStep {
  id: string;
  position: number;
  name: string;
  parameters: Parameter[];
}

interface Protocol {
  steps: SerializedStep[];
}

import type { ValidationError } from "@/state/validationState";
import { Node } from "@tiptap/pm/model";

export function serializeProtocolSteps(doc: Node): Protocol {
  const serializedSteps: SerializedStep[] = [];

  // Process each node in the document, tracking positions
  doc.descendants((node: Node, pos: number) => {
    if (node.type.name !== "step") return true;

    const step: SerializedStep = {
      id: node.attrs.uid,
      position: pos + 1,
      name: "",
      parameters: [],
    };

    // Extract step name
    node.descendants((childNode: Node) => {
      if (childNode.type.name === "stepName" && childNode.textContent) {
        step.name = childNode.textContent;
        return false; // Stop traversing this branch
      }
      return true;
    });

    // Extract parameters
    node.descendants((childNode: Node, childPos: number) => {
      if (childNode.type.name === "parameter") {
        let paramName = "";
        let paramValue = "";
        let namePosition = 0;
        let valuePosition = 0;
        const paramPosition = pos + childPos + 1; // Add +1 offset consistently
        const paramUid = childNode.attrs.uid || crypto.randomUUID(); // Get UID from attrs or generate new one

        childNode.descendants((paramChild: Node, paramChildPos: number) => {
          if (paramChild.type.name === "paramName") {
            paramName = paramChild.textContent;
            namePosition = paramPosition + paramChildPos + 1;
          }
          if (paramChild.type.name === "paramValue") {
            // Check if paramValue contains a selectValue node
            let hasSelectValue = false;

            paramChild.descendants((valueChild: Node) => {
              if (
                valueChild.type.name === "selectValue" &&
                valueChild.attrs.selectedId
              ) {
                // Use the selectedId as the parameter value
                paramValue = valueChild.attrs.selectedId;
                hasSelectValue = true;
                return false; // Stop traversing this branch
              }
              return true;
            });

            // If no selectValue was found, use the textContent as before
            if (!hasSelectValue) {
              paramValue = paramChild.textContent;
            }

            valuePosition = paramPosition + paramChildPos + 1;
          }
          return true;
        });

        step.parameters.push({
          uid: paramUid,
          name: paramName,
          value: paramValue,
          position: paramPosition,
          namePosition,
          valuePosition,
        });
      }
      return true;
    });

    serializedSteps.push(step);
    return true; // Continue traversing
  });

  return {
    steps: serializedSteps,
  };
}

export function findStepById(
  doc: Node,
  stepId: string
): { position: number; node: Node } | null {
  let foundStep: { position: number; node: Node } | null = null;

  doc.descendants((node: Node, pos: number) => {
    if (node.type.name === "step" && node.attrs.uid === stepId) {
      foundStep = {
        position: pos,
        node: node,
      };
      return false; // Stop traversing once found
    }
    return true;
  });

  return foundStep;
}

/**
 * Groups validation errors by their stepId
 *
 * @param allErrors List of all validation errors
 * @returns An object with stepIds as keys and arrays of errors as values
 */
export function groupErrorsByStep(
  allErrors: ValidationError[]
): Record<string, ValidationError[]> {
  const errorsByStep: Record<string, ValidationError[]> = {};

  for (const error of allErrors) {
    if (!error.stepId) continue;

    if (!errorsByStep[error.stepId]) {
      errorsByStep[error.stepId] = [];
    }

    errorsByStep[error.stepId].push(error);
  }

  return errorsByStep;
}
