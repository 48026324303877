<template>
  <StateDebugger v-if="isDev" />
  <router-view></router-view>
  <Toaster></Toaster>
  <Sonner position="bottom-left"></Sonner>
</template>

<script setup lang="ts">
import { watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useCurrentUser } from "vuefire";
import posthog from "posthog-js";
import Toaster from "@/components/ui/toast/Toaster.vue";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { isImpersonating } from "@/authManager";
import StateDebugger from "./components/StateDebugger.vue";

// NOTE: Please don't put any global logic here which can leave in separate files.
// Keep this file lean.

const user = useCurrentUser();
const router = useRouter();
const route = useRoute();

const isDev = import.meta.env.DEV;

watch(user, async (currentUser, previousUser) => {
  if (currentUser) {
    // Always identify as the current user
    posthog.identify(currentUser.email ?? currentUser.uid, {
      email: currentUser.email,
    });

    // If impersonating, register this as a property that will be sent with all events
    if (isImpersonating()) {
      const originalAdmin = sessionStorage.getItem("impersonator");
      posthog.register({
        is_impersonation: true,
        impersonator_id: originalAdmin,
      });
    }
  } else {
    console.log("User not identified");
    posthog.startSessionRecording();
  }
  if (!currentUser && previousUser && route.meta.requiresAuth) {
    router.push("/login");
  }
});
</script>
