import type { ChatCompletionMessageParam } from "openai/resources/chat/completions";

export interface PromptOptions {
  model: string;
  messages: Array<ChatCompletionMessageParam>;
  maxTokens?: number;
  stopTokens?: string[];
  systemPrompt?: string;
  temperature?: number;
  topP?: number;
  frequencyPenalty?: number;
  responseFormat?: "json_object" | "text";
  userToken?: string;
}

export async function* promptApi(
  baseUrl: string,
  promptOptions: PromptOptions
) {
  const res = await fetch(`${baseUrl}/api/stream`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${promptOptions.userToken}`,
    },
    body: JSON.stringify(promptOptions),
  });

  if (res.body == null) {
    throw new Error("Response body is null");
  }

  const decoder = new TextDecoder("utf-8");
  const reader = res.body.getReader();

  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }
    const decodedChunk = decoder.decode(value);
    yield decodedChunk;
  }
}
