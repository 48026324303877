import type { EditorView } from "@tiptap/pm/view";
import { ValidationService } from "@/automation/system-config/systemConfigValidator";
import { serializeProtocolSteps } from "@/automation/utils/docUtils";
import { ref } from "vue";
import { useConfigState } from "./configState";

export type ValidationStatus = "idle" | "validating" | "valid" | "invalid";

export interface MethodValidation {
  validationConfigId: string; // Reference to the system_configs/{configId}
  lastValidated?: Date; // Optional timestamp of last validation
  isValid?: boolean; // Optional status of last validation
}

// Re-export types from configState for backward compatibility
export type { SystemConfig, ConfigMetadata } from "./configState";

export interface ValidationError {
  id: string;
  position: number;
  message: string;
  stepId: string;
  context?: {
    stepData?: any;
    errorType?: string;
  };
}

/**
 * These are the globally available states managed by the validation state composable.
 */
const validationStatus = ref<ValidationStatus>("idle");
const errors = ref<ValidationError[]>([]);
const validationService = new ValidationService();

/**
 * Global state store for validation related state
 */
export function useValidationState() {
  // Get config-related state needed for validation
  const { activeConfig } = useConfigState();

  function setErrors(view: EditorView, newErrors: ValidationError[]) {
    errors.value = newErrors;
    view.dispatch(view.state.tr.setMeta("validation", { errors: newErrors }));
  }

  function clearErrors(view: EditorView) {
    errors.value = [];
    view.dispatch(view.state.tr.setMeta("validation", { errors: [] }));
  }

  function addError(view: EditorView, error: ValidationError) {
    const newErrors = [...errors.value, error];
    setErrors(view, newErrors);
  }

  /**
   * Validates the editor content against the active validation config.
   * This will generate errors that is handled by the linter.
   */
  async function applyValidationToEditor(view: EditorView) {
    if (!activeConfig.value) {
      console.warn("No active validation config");
      return;
    }

    try {
      validationStatus.value = "validating";

      // Convert editor content to serialized steps
      const steps = serializeProtocolSteps(view.state.doc);

      // Load the validation config into the service
      validationService.loadConfig(activeConfig.value.configText);

      // Validate the steps against the schema, passing the document for labware validation
      const result = validationService.validateSteps(steps.steps, {
        document: view.state.doc,
      });

      // Update validation state
      validationStatus.value = result.isValid ? "valid" : "invalid";

      const editorErrors = result.errors
        .map((error) => {
          if (error.position === undefined) {
            console.warn(`Could not find position for error: ${error.message}`);
            return null;
          }

          // Return error with the actual prosemirror position and ID
          return {
            id: error.id,
            position: error.position,
            message: error.message,
            stepId: error.stepId,
            context: error.context,
          } as ValidationError;
        })
        .filter((error): error is ValidationError => error !== null);

      setErrors(view, editorErrors);

      return result;
    } catch (error) {
      console.error("Error validating editor content:", error);
      validationStatus.value = "invalid";
      throw error;
    }
  }

  return {
    // State
    validationStatus,
    errors,

    // Methods
    setErrors,
    clearErrors,
    addError,
    applyValidationToEditor,
  };
}
