/**
 * Cleans up JSON responses from AI models that might contain markdown formatting
 * @param response The raw response from the AI model
 * @returns Cleaned JSON string ready for parsing
 */
export function cleanJsonResponse(response: string): string {
  let cleanedResponse = response.trim();

  // Remove ```json at the start if present
  if (cleanedResponse.startsWith("```json")) {
    cleanedResponse = cleanedResponse.replace("```json", "").trim();
  }

  // Remove ``` at the start if present (in case the language wasn't specified)
  if (cleanedResponse.startsWith("```")) {
    cleanedResponse = cleanedResponse.replace("```", "").trim();
  }

  // Remove ``` at the end if present
  if (cleanedResponse.endsWith("```")) {
    cleanedResponse = cleanedResponse.slice(0, -3).trim();
  }

  return cleanedResponse;
}
