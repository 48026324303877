import { ref } from "vue";
import { getCurrentUser } from "vuefire";

// Shared state that can be accessed across components
const userToken = ref<string | null>(null);
const isLoadingToken = ref(false);
const tokenError = ref<Error | null>(null);

/**
 * Composable for managing user authentication state
 */
export function useUserState() {
  /**
   * Fetches the Firebase ID token for the currently authenticated user
   * @returns A promise that resolves to the user's ID token, or null if no user is authenticated
   */
  async function getUserToken(): Promise<string | null> {
    try {
      isLoadingToken.value = true;
      tokenError.value = null;

      const currentUser = await getCurrentUser();

      if (!currentUser) {
        userToken.value = null;
        return null;
      }

      const token = await currentUser.getIdToken();
      userToken.value = token;
      return token;
    } catch (error) {
      console.error("Error getting user token:", error);
      tokenError.value =
        error instanceof Error ? error : new Error(String(error));
      userToken.value = null;
      return null;
    } finally {
      isLoadingToken.value = false;
    }
  }

  return {
    // State
    userToken,
    isLoadingToken,
    tokenError,

    // Methods
    getUserToken,
  };
}
