import { db } from "@/firebase";
import type { ConfigMetadata, MethodValidation } from "@/state/validationState";
import type { SystemConfig } from "@/state/configState";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { parseFullConfigXml } from "./systemConfigParser";

/**
 * Fetches all system configurations created by a specific user in a workspace
 *
 * @param workspaceId - The ID of the workspace
 * @param userId - The ID of the user who created the configs
 * @returns A promise that resolves to an array of configuration metadata
 */
export async function fetchUserConfigs(
  workspaceId: string,
  userId: string
): Promise<ConfigMetadata[]> {
  try {
    // Reference to the system_configs subcollection
    const configsRef = collection(
      db,
      `workspaces/${workspaceId}/system_configs`
    );

    // Create a query to get configs owned by the user
    const configsQuery = query(configsRef, where("createdBy", "==", userId));
    const querySnapshot = await getDocs(configsQuery);

    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      name: doc.id, // Assuming the document ID is the config name
      createdAt: doc.data().createdAt.toDate(),
      createdBy: doc.data().createdBy,
    }));
  } catch (error) {
    console.error("Error fetching user configs:", error);
    throw new Error("Failed to fetch user configurations");
  }
}

/**
 * Loads a specific system configuration from a workspace
 *
 * @param workspaceId - The ID of the workspace
 * @param configName - The name/ID of the configuration to load
 * @returns A promise that resolves to the system configuration
 */
export async function loadSystemConfig(
  workspaceId: string,
  configName: string
): Promise<SystemConfig> {
  try {
    const configRef = doc(
      db,
      `workspaces/${workspaceId}/system_configs/${configName}`
    );
    const configDoc = await getDoc(configRef);

    if (!configDoc.exists()) {
      throw new Error(`System configuration not found: ${configName}`);
    }

    const data = configDoc.data();
    const parsedConfig = parseFullConfigXml(data.configText);

    return {
      name: configName,
      configText: data.configText,
      createdAt: data.createdAt,
      createdBy: data.createdBy,
      workspaceId: data.workspaceId,
      robots: parsedConfig.robots,
      labware: parsedConfig.labware,
      heads: parsedConfig.heads,
      steps: parsedConfig.steps,
    };
  } catch (error) {
    console.error("Error loading system config:", error);
    throw new Error(`Failed to load system configuration: ${configName}`);
  }
}

/**
 * Gets the validation config for a method along with the full system config if available
 * @param workspaceId - The ID of the workspace
 * @param methodId - The ID of the method
 * @returns A promise that resolves to the method validation with system config if available
 */
export async function getMethodValidationConfig(
  workspaceId: string,
  methodId: string
): Promise<{ validation: MethodValidation | null; config?: SystemConfig }> {
  try {
    const methodRef = doc(db, `workspaces/${workspaceId}/methods/${methodId}`);
    const methodDoc = await getDoc(methodRef);

    if (!methodDoc.exists()) {
      throw new Error("Method not found");
    }

    const data = methodDoc.data();
    const validation = data.validation || null;

    // If there's a validation config ID, load the full config
    let config: SystemConfig | undefined;
    if (validation?.validationConfigId) {
      try {
        config = await loadSystemConfig(
          workspaceId,
          validation.validationConfigId
        );
      } catch (error) {
        console.error("Error loading validation config content:", error);
      }
    }

    return { validation, config };
  } catch (error) {
    console.error("Error getting method validation config:", error);
    throw error;
  }
}

/**
 * Connects a method to a validation config
 */
export async function connectMethodToConfig(
  workspaceId: string,
  methodId: string,
  configId: string
) {
  try {
    const methodRef = doc(db, `workspaces/${workspaceId}/methods/${methodId}`);

    await updateDoc(methodRef, {
      validation: {
        validationConfigId: configId,
        lastValidated: null,
        isValid: null,
      },
    });

    return true;
  } catch (error) {
    console.error("Error connecting method to config:", error);
    throw error;
  }
}
