<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { startImpersonation } from "../authManager";
import { httpsCallable } from "@firebase/functions";
import { functions } from "../firebase";

const route = useRoute();
const router = useRouter();
const error = ref<string | null>(null);

const generateImpersonationToken = httpsCallable<
  { targetEmail: string },
  { token: string }
>(functions, "generateImpersonationToken");

onMounted(async () => {
  try {
    const email = route.query.email as string;
    if (!email) {
      error.value = "No email provided";
      return;
    }

    // Start impersonation using email
    const {
      data: { token },
    } = await generateImpersonationToken({ targetEmail: email });
    await startImpersonation(email, token);

    // Redirect to home or specified redirect URL
    const redirectUrl = (route.query.redirect as string) || "/";
    router.push(redirectUrl);
  } catch (e: any) {
    error.value = e.message || "Failed to start impersonation";
  }
});
</script>

<template>
  <div class="flex min-h-screen items-center justify-center">
    <div v-if="error" class="text-red-500">
      {{ error }}
    </div>
    <div v-else class="text-gray-600">Impersonating user...</div>
  </div>
</template>
