import { ref, computed } from "vue";
import { useConfigState } from "./configState";
import type { LabwareObject } from "@/automation/labware/labwareManager";
import { createDeckConfigurationFromLayout } from "@/automation/system-config/defaultDeckLayouts";

export interface DeckPosition {
  id: string;
  allowedLabware?: string[];
  currentLabware?: LabwareObject;
  isVerified?: boolean;
}

export interface DeckConfiguration {
  id: string;
  label: string;
  positions: DeckPosition[];
  sections: {
    id: string;
    label: string;
    positionIds: string[];
  }[];
}

export interface RobotConfig {
  id: string;
  label: string;
  deckLayoutId: string;
  compatibleHeads: string[];
}

export interface DeckLayoutDefinition {
  id: string;
  label: string;
  positions: DeckPosition[];
  sections: {
    id: string;
    label: string;
    positionIds: string[];
  }[];
}

// Create a single global state instance
const { activeRobot, activeConfig } = useConfigState();
const deckConfiguration = ref<DeckConfiguration | null>(null);
const verificationStatus = ref<Record<string, boolean>>({});
const requiredLabware = ref<LabwareObject[]>([]);
const unassignedLabware = ref<LabwareObject[]>([]);

// Computed value to get the current deck layout ID based on the active robot
const currentDeckLayoutId = computed(() => {
  return activeRobot.value?.deckLayoutId || "standard-dual-deck"; // Default layout if none specified
});

// Helper function to get the layoutId from robotId
const getLayoutIdForRobot = (robotId: string): string => {
  // In a real app, this would check a mapping table or fetch from database
  // For now, we're using the robot's deckLayoutId property or a default
  if (activeConfig.value) {
    const robot = activeConfig.value.robots.find((r) => r.id === robotId);
    if (robot?.deckLayoutId) {
      return robot.deckLayoutId;
    }
  }
  return "standard-dual-deck"; // Default layout if no robot found
};

// Computed values
const isReady = computed(() => {
  if (!deckConfiguration.value) return false;

  // Check all positions with labware
  return deckConfiguration.value.positions.every(
    (position) => !position.currentLabware || position.isVerified
  );
});

// Methods
const setDeckConfiguration = (
  config: DeckConfiguration,
  unassigned: LabwareObject[] = []
) => {
  deckConfiguration.value = config;
  unassignedLabware.value = unassigned;

  // Initialize verification status for each position
  const newVerificationStatus: Record<string, boolean> = {};
  config.positions.forEach((position) => {
    newVerificationStatus[position.id] = position.isVerified || false;
  });
  verificationStatus.value = newVerificationStatus;
};

// Initialize deck configuration based on active robot
const initDeckConfiguration = () => {
  if (!activeRobot.value) {
    throw new Error("Error initializing deck configuration: No active robot");
  }

  // Get the layout ID for the active robot
  const layoutId = activeRobot.value?.deckLayoutId || "standard-dual-deck";

  // Create the deck configuration using the layout definition
  const config = createDeckConfigurationFromLayout(layoutId);

  if (config) {
    setDeckConfiguration(config);
    return config;
  }

  return null;
};

const verifyPosition = (positionId: string, isVerified: boolean) => {
  verificationStatus.value[positionId] = isVerified;

  // Update the deck configuration with the new verification status
  if (deckConfiguration.value) {
    deckConfiguration.value.positions = deckConfiguration.value.positions.map(
      (position) => {
        if (position.id === positionId) {
          return { ...position, isVerified };
        }
        return position;
      }
    );
  }
};

const setRequiredLabware = (labware: LabwareObject[]) => {
  requiredLabware.value = labware;
};

// Export a function that returns the global state
export const useDeckLayoutState = () => {
  return {
    deckConfiguration,
    currentDeckLayoutId,
    verificationStatus,
    requiredLabware,
    unassignedLabware,
    isReady,
    setDeckConfiguration,
    initDeckConfiguration,
    verifyPosition,
    setRequiredLabware,
    getLayoutIdForRobot,
  };
};
