import { DOMParser } from "@xmldom/xmldom";
import { XMLParser } from "fast-xml-parser";

export interface ConfigParam {
  name: string;
  type: string;
  required: boolean;
  min?: number;
  max?: number;
  unit?: string;
  options?: string[];
}

export interface ConfigStep {
  name: string;
  params: ConfigParam[];
}

export interface ParsedConfig {
  steps: ConfigStep[];
}

export interface LabwareSpec {
  name: string;
  value: string | number;
  unit?: string;
}

export interface LabwareType {
  id: string;
  label: string;
  specifications: LabwareSpec[];
}

export interface HeadSpec {
  name: string;
  value: string; // Always a string for head specs
  unit?: string;
}

export interface Head {
  id: string;
  label: string;
  specifications: HeadSpec[];
}

export interface Robot {
  id: string;
  label: string;
  deckLayoutId: string;
  compatibleHeads: string[];
}

export interface ParsedFullConfig {
  labware: LabwareType[];
  heads: Head[];
  robots: Robot[];
  steps: ConfigStep[];
}

/**
 * Parses XML configuration string into a structured object
 * @param xmlString The XML configuration string to parse
 * @returns Parsed configuration object
 */
export function parseConfigXml(xmlString: string): ParsedConfig {
  try {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");

    const result: ParsedConfig = { steps: [] };

    // Get all step elements
    const stepElements = xmlDoc.getElementsByTagName("step");

    for (let i = 0; i < stepElements.length; i++) {
      const stepElement = stepElements[i];
      const stepName = stepElement.getAttribute("name") || `Step ${i + 1}`;

      const step: ConfigStep = {
        name: stepName,
        params: [],
      };

      // Get all param elements within this step
      const paramElements = stepElement.getElementsByTagName("param");

      for (let j = 0; j < paramElements.length; j++) {
        const paramElement = paramElements[j];
        const paramName =
          paramElement.getAttribute("name") || `Parameter ${j + 1}`;
        const paramType = paramElement.getAttribute("type") || "unknown";
        const paramRequired = paramElement.getAttribute("required") === "true";

        const param: ConfigParam = {
          name: paramName,
          type: paramType,
          required: paramRequired,
        };

        // Parse min, max, and unit attributes if they exist
        if (paramElement.hasAttribute("min")) {
          param.min = parseFloat(paramElement.getAttribute("min") || "0");
        }

        if (paramElement.hasAttribute("max")) {
          param.max = parseFloat(paramElement.getAttribute("max") || "0");
        }

        if (paramElement.hasAttribute("unit")) {
          param.unit = paramElement.getAttribute("unit") || "";
        }

        // Parse options for select type parameters
        const optionsElement = paramElement.getElementsByTagName("options")[0];
        if (optionsElement) {
          const optionElements = optionsElement.getElementsByTagName("option");
          param.options = [];

          for (let k = 0; k < optionElements.length; k++) {
            const optionElement = optionElements[k];
            const optionText = optionElement.textContent || "";
            if (optionText) {
              param.options.push(optionText);
            }
          }
        }

        step.params.push(param);
      }

      result.steps.push(step);
    }

    return result;
  } catch (error) {
    console.error("Error parsing XML:", error);
    throw new Error("Failed to parse configuration XML");
  }
}

export function parseFullConfigXml(xmlString: string): ParsedFullConfig {
  const parser = new XMLParser({
    ignoreAttributes: false,
    attributeNamePrefix: "",
  });
  const xmlDoc = parser.parse(xmlString);
  const labware: LabwareType[] = [];
  const heads: Head[] = [];
  const robots: Robot[] = [];
  let steps: ConfigStep[] = [];

  try {
    // Parse heads if they exist
    if (
      xmlDoc["validation-rules-schema"] &&
      xmlDoc["validation-rules-schema"]["heads"]
    ) {
      const headElements = xmlDoc["validation-rules-schema"]["heads"].head;

      if (headElements) {
        const headArray = Array.isArray(headElements)
          ? headElements
          : [headElements];

        headArray.forEach((head: any) => {
          try {
            if (head && head.id && head.label && head.specifications) {
              const specs: HeadSpec[] = [];

              if (Array.isArray(head.specifications.spec)) {
                head.specifications.spec.forEach((spec: any) => {
                  if (spec && spec.name) {
                    specs.push({
                      name: spec.name,
                      value:
                        spec.name === "dimensions"
                          ? String(spec.value)
                          : isNaN(Number(spec.value))
                          ? spec.value
                          : Number(spec.value),
                      unit: spec.unit,
                    });
                  }
                });
              } else if (
                head.specifications.spec &&
                head.specifications.spec.name
              ) {
                specs.push({
                  name: head.specifications.spec.name,
                  value:
                    head.specifications.spec.name === "dimensions"
                      ? String(head.specifications.spec.value)
                      : isNaN(Number(head.specifications.spec.value))
                      ? head.specifications.spec.value
                      : Number(head.specifications.spec.value),
                  unit: head.specifications.spec.unit,
                });
              }

              heads.push({
                id: head.id,
                label: head.label,
                specifications: specs,
              });
            }
          } catch (error) {
            console.error("Error parsing head:", error);
          }
        });
      }
    }

    // Parse labware types if they exist
    if (
      xmlDoc["validation-rules-schema"] &&
      xmlDoc["validation-rules-schema"]["labware-types"]
    ) {
      const labwareElements =
        xmlDoc["validation-rules-schema"]["labware-types"].type;

      if (labwareElements) {
        const labwareArray = Array.isArray(labwareElements)
          ? labwareElements
          : [labwareElements];

        labwareArray.forEach((lw: any) => {
          try {
            if (lw && lw.id && lw.label && lw.specifications) {
              const specs: LabwareSpec[] = [];

              if (Array.isArray(lw.specifications.spec)) {
                lw.specifications.spec.forEach((spec: any) => {
                  if (spec && spec.name) {
                    specs.push({
                      name: spec.name,
                      value: isNaN(Number(spec.value))
                        ? spec.value
                        : Number(spec.value),
                      unit: spec.unit,
                    });
                  }
                });
              } else if (
                lw.specifications.spec &&
                lw.specifications.spec.name
              ) {
                specs.push({
                  name: lw.specifications.spec.name,
                  value: isNaN(Number(lw.specifications.spec.value))
                    ? lw.specifications.spec.value
                    : Number(lw.specifications.spec.value),
                  unit: lw.specifications.spec.unit,
                });
              }

              labware.push({
                id: lw.id,
                label: lw.label,
                specifications: specs,
              });
            }
          } catch (e) {
            console.error("Error parsing labware item:", e);
          }
        });
      }
    }

    // Parse robots if they exist
    if (
      xmlDoc["validation-rules-schema"] &&
      xmlDoc["validation-rules-schema"]["robots"]
    ) {
      const robotElements = xmlDoc["validation-rules-schema"]["robots"].robot;

      if (robotElements) {
        const robotArray = Array.isArray(robotElements)
          ? robotElements
          : [robotElements];

        robotArray.forEach((robot: any) => {
          try {
            if (robot && robot.id && robot.label) {
              const deckLayoutId = robot["deck-layout"]
                ? robot["deck-layout"].id
                : null;
              let compatibleHeads: string[] = [];

              if (
                robot.compatibility &&
                robot.compatibility["head-types"] &&
                robot.compatibility["head-types"]["head-type"]
              ) {
                const headTypes =
                  robot.compatibility["head-types"]["head-type"];

                if (Array.isArray(headTypes)) {
                  compatibleHeads = headTypes;
                } else {
                  compatibleHeads = [headTypes];
                }
              }

              robots.push({
                id: robot.id,
                label: robot.label,
                deckLayoutId: deckLayoutId || "standard-dual-deck", // Default value if not found
                compatibleHeads: compatibleHeads,
              });
            }
          } catch (error) {
            console.error("Error parsing robot:", error);
          }
        });
      }
    }

    // Parse steps
    try {
      steps = parseConfigXml(xmlString).steps;
    } catch (e) {
      console.error("Error parsing steps:", e);
    }

    // Return the parsed config
    return {
      labware,
      heads,
      robots,
      steps,
    };
  } catch (error) {
    console.error("Error parsing XML:", error);
    throw new Error("Failed to parse configuration XML");
  }
}
