import { fetchUserConfigs } from "@/automation/system-config/systemConfigApi";
import type {
  ConfigStep,
  LabwareType,
  Head,
  Robot,
} from "@/automation/system-config/systemConfigParser";
import { ref, computed } from "vue";

export interface SystemConfig {
  name: string;
  configText: string;
  createdAt: Date;
  createdBy: string;
  labware: LabwareType[];
  heads: Head[];
  robots: Robot[];
  steps: ConfigStep[];
  workspaceId: string;
}

export interface ConfigMetadata {
  id: string;
  name: string;
  createdAt: Date;
  createdBy: string;
}

/**
 * These are the globally available states managed by the config state composable.
 */
const activeConfig = ref<SystemConfig | undefined>(undefined);
const activeRobot = ref<Robot | null>(null);
const availableConfigs = ref<ConfigMetadata[]>([]);
const showConfigDialog = ref<boolean>(false);

/**
 * Global state store for system configuration related state
 */
export function useConfigState() {
  async function loadUserConfigs(workspaceId: string, userId: string) {
    try {
      const configs = await fetchUserConfigs(workspaceId, userId);
      setAvailableConfigs(configs);
      return configs;
    } catch (error) {
      console.error("Error loading configs:", error);
      throw error;
    }
  }

  function setActiveConfig(config: SystemConfig | undefined) {
    activeConfig.value = config;

    // Reset active robot when config changes
    if (config && config.robots && config.robots.length > 0) {
      activeRobot.value = config.robots[0];
    } else {
      activeRobot.value = null;
    }
  }

  function setActiveRobot(robotId: string) {
    if (!activeConfig.value) return;

    const robot = activeConfig.value.robots.find((r) => r.id === robotId);
    if (robot) {
      activeRobot.value = robot;
    }
  }

  function setAvailableConfigs(configs: ConfigMetadata[]) {
    availableConfigs.value = configs;
  }

  /**
   * Sets the visibility of the config dialog
   */
  function setShowConfigDialog(show: boolean) {
    showConfigDialog.value = show;
  }

  return {
    // State
    activeConfig,
    activeRobot,
    availableConfigs,
    showConfigDialog,

    // Methods
    loadUserConfigs,
    setActiveConfig,
    setActiveRobot,
    setAvailableConfigs,
    setShowConfigDialog,
  };
}
