import { ref } from "vue";
import {
  useValidationState,
  type ValidationError,
} from "@/state/validationState";
import { useConfigState } from "@/state/configState";
import { useMethodState } from "@/state/methodState";
import { useUserState } from "@/state/userState";
import { promptApi } from "@/automation/utils/promptApi";
import { cleanJsonResponse } from "@/automation/utils/aiUtils";
import {
  serializeProtocolSteps,
  findStepById,
  groupErrorsByStep,
} from "@/automation/utils/docUtils";
import { createStepDiff } from "@/automation/validation/error-fixer/stepDiffer";
import type { ChatCompletionMessageParam } from "openai/resources/chat/completions";
import { EditorView } from "@tiptap/pm/view";
import errorFixPrompt from "@/automation/validation/error-fixer/error-fix-prompt.sys.md?raw";
import { v4 as uuidv4 } from "uuid";

export type PromptStatus = "composing" | "generating" | "generated" | "error";

export interface FixedStepParameter {
  uid: string;
  name: string;
  value: string;
}

export interface AIResponse {
  reasoning: string;
  fixedStep: {
    id: string;
    type: "step";
    name: string;
    parameters?: FixedStepParameter[];
  };
  newLabwareItems?: Array<{
    id: string;
    typeId: string;
    label: string;
    properties?: Record<string, string>;
  }>;
}

const ACTIVE_AI_MODEL = "google/gemini-2.0-flash-001";
// const ACTIVE_AI_MODEL = "deepseek/deepseek-r1-distill-qwen-1.5b";

/**
 * These are the globally available states managed by the AI state composable.
 */
const promptStatus = ref<PromptStatus>("composing");
const isFixingAll = ref(false);
const currentFixProgress = ref(0);
const totalFixCount = ref(0);
const stepsBeingFixed = ref<Set<string>>(new Set());

// Get the user token from userState
const { getUserToken } = useUserState();

/**
 * This is the global state store for all AI related state.
 */
export function useAIState() {
  const { errors, setErrors } = useValidationState();
  const { activeConfig } = useConfigState();
  const { activeMethodEditor } = useMethodState();

  function setPromptStatus(status: PromptStatus) {
    promptStatus.value = status;
  }

  /**
   * Marks a step as being fixed by AI
   *
   * @param stepId The ID of the step being fixed
   */
  function addStepBeingFixed(stepId: string): void {
    stepsBeingFixed.value.add(stepId);
  }

  /**
   * Marks a step as no longer being fixed by AI
   *
   * @param stepId The ID of the step that was being fixed
   */
  function removeStepBeingFixed(stepId: string): void {
    stepsBeingFixed.value.delete(stepId);
  }

  /**
   * Fixes a single validation error using AI
   *
   * @param errorIds The ID of the error to fix
   * @returns A promise that resolves when the fix is complete
   */
  async function fixSingleStep(errorIds: string[]): Promise<boolean> {
    if (!activeConfig.value || !activeMethodEditor.value) {
      throw new Error("No active configuration or editor found");
    }

    try {
      setPromptStatus("generating");

      // Get all errors from the global state
      const selectedErrors = errors.value.filter((e) =>
        errorIds.includes(e.id)
      );
      if (selectedErrors.length === 0) {
        throw new Error(`No errors found for the provided IDs`);
      }

      // Use the first error to determine the step
      const primaryError = selectedErrors[0];
      if (!primaryError) {
        throw new Error("No primary error found");
      }

      const view = activeMethodEditor.value.view;

      // Get all errors for the same step
      const stepErrors = primaryError.stepId
        ? errors.value.filter((e) => e.stepId === primaryError.stepId)
        : selectedErrors;

      // Mark the step as being fixed
      if (primaryError.stepId) {
        addStepBeingFixed(primaryError.stepId);
      }

      try {
        // Apply the fix with selected errors and all related step errors
        const success = await applyAIFix(
          selectedErrors,
          view as EditorView,
          stepErrors
        );
        setPromptStatus("generated");
        return success;
      } finally {
        // Always remove the step from being fixed, even if there was an error
        if (primaryError.stepId) {
          removeStepBeingFixed(primaryError.stepId);
        }
      }
    } catch (err) {
      console.error("Failed to fix errors:", err);
      setPromptStatus("error");
      return false;
    }
  }

  /**
   * Fixes all errors in the document, processing them step by step
   *
   * @returns A promise that resolves when all fixes are complete
   */
  async function fixAllErrors(): Promise<boolean> {
    if (!activeMethodEditor.value || !activeConfig.value) {
      throw new Error("No active editor or config found");
    }

    try {
      isFixingAll.value = true;
      setPromptStatus("generating");

      const view = activeMethodEditor.value.view;

      // Group errors by stepId to fix one step at a time
      const errorsByStep = groupErrorsByStep(errors.value);

      // Set progress tracking
      totalFixCount.value = Object.keys(errorsByStep).length;
      currentFixProgress.value = 0;

      // Process each step's errors
      for (const [stepId, stepErrors] of Object.entries(errorsByStep)) {
        // We'll take the first error for each step to identify the step
        const firstError = stepErrors[0];

        try {
          // Mark the step as being fixed
          addStepBeingFixed(stepId);

          await applyAIFix(firstError, view as EditorView, stepErrors);
          currentFixProgress.value++;
        } catch (err) {
          console.error(`Failed to fix errors for step ${stepId}:`, err);
          // Continue with other steps even if one fails
        } finally {
          // Always remove the step from being fixed
          removeStepBeingFixed(stepId);
        }
      }

      setPromptStatus("generated");
      setErrors(view as EditorView, []);
      return true;
    } catch (err) {
      console.error("Failed to fix all errors:", err);
      setPromptStatus("error");
      return false;
    } finally {
      isFixingAll.value = false;
    }
  }

  /**
   * Applies an AI fix to a step in the document
   *
   * @param error The error to fix
   * @param view The editor view
   * @param allStepErrors Optional array of all errors for this step
   * @returns A promise that resolves when the fix is applied
   */
  async function applyAIFix(
    error: ValidationError | ValidationError[],
    view: EditorView,
    allStepErrors?: ValidationError[]
  ): Promise<boolean> {
    if (!activeConfig.value) {
      throw new Error("No active configuration found");
    }

    // Handle single error or array of errors
    const errors = Array.isArray(error) ? error : [error];

    // Use the first error to get the step ID
    const primaryError = errors[0];
    if (!primaryError) {
      throw new Error("No primary error provided");
    }

    // Get the current step data using stepId
    if (!primaryError.stepId) {
      throw new Error("No step ID found in primary error");
    }

    const stepResult = findStepById(view.state.doc, primaryError.stepId);
    if (!stepResult) {
      throw new Error("Could not find step in document");
    }

    // Get the current step data
    const serializedProtocol = serializeProtocolSteps(view.state.doc);
    const currentStep = serializedProtocol.steps
      .map((step) => ({
        ...step,
        position: step.position - 1,
      }))
      .find((step) => step.id === primaryError.stepId);

    if (!currentStep) {
      throw new Error("Could not find current step");
    }

    // Format errors for the prompt
    // Include all selected errors
    let errorMessages = errors
      .map((err) => `    <e>${err.message}</e>`)
      .join("\n");

    // If we're fixing all errors for a step, include all of them in the prompt
    if (allStepErrors && allStepErrors.length > errors.length) {
      // Only include errors that aren't already in the list
      const additionalErrors = allStepErrors.filter(
        (stepErr) => !errors.some((err) => err.id === stepErr.id)
      );

      if (additionalErrors.length > 0) {
        const additionalErrorMessages = additionalErrors
          .map((err) => `    <e>${err.message}</e>`)
          .join("\n");

        errorMessages += "\n" + additionalErrorMessages;
      }
    }

    // Get existing labware from the document
    const docAttrs = view.state.doc.attrs || {};
    const selectOptions = docAttrs.selectOptions || {};
    const existingLabware = selectOptions.labware || [];

    // Format the input according to the system prompt specification
    const input = `
<input>
  <validation-schema>
    ${activeConfig.value.configText}
  </validation-schema>

  <protocol-step>
    ${JSON.stringify(currentStep, null, 2)}
  </protocol-step>
  
  <existing-labware>
    ${JSON.stringify(existingLabware, null, 2)}
  </existing-labware>

  <validation-errors>
${errorMessages}
  </validation-errors>
</input>
`;

    // Prepare messages for the AI
    const messages: ChatCompletionMessageParam[] = [
      {
        role: "user",
        content: input,
      },
    ];

    // Get the current user token
    const userToken = await getUserToken();

    if (!userToken) {
      throw new Error("No user token found");
    }

    // Call the AI service
    const promptOptions = {
      model: ACTIVE_AI_MODEL,
      messages,
      maxTokens: 2000,
      temperature: 0.2,
      responseFormat: "json_object" as const,
      systemPrompt: errorFixPrompt,
      userToken,
    };

    let response = "";
    for await (const chunk of promptApi(
      "https://ai-api-181245091241.europe-west4.run.app",
      promptOptions
    )) {
      response += chunk;
    }

    // Parse the AI response
    const aiResponse = JSON.parse(cleanJsonResponse(response)) as AIResponse;

    // Validate that the response follows the expected format
    if (!aiResponse.reasoning || !aiResponse.fixedStep) {
      throw new Error("Invalid AI response format");
    }

    // Start with the current transaction
    let tr = view.state.tr;

    // Update AI generated IDs with real UUIDs
    const updatedAIResponse = updateAIGeneratedIds(aiResponse);

    // Process new labware items if they exist
    if (updatedAIResponse.newLabwareItems?.length) {
      // Import the labware manager
      const { updateDocumentLabware } = await import(
        "@/automation/labware/labwareManager"
      );

      // Update the transaction with labware changes
      updateDocumentLabware(
        { state: view.state } as any, // Adapt to the expected interface
        tr,
        updatedAIResponse.newLabwareItems
      );
    }

    // Convert the AI's fixedStep into the format expected by createStepDiff
    const newStep = {
      id: updatedAIResponse.fixedStep.id,
      position: stepResult.position,
      name: updatedAIResponse.fixedStep.name,
      parameters:
        updatedAIResponse.fixedStep.parameters?.map((param) => ({
          uid: param.uid || uuidv4(),
          name: param.name,
          value: param.value,
          position: -1, // Will be calculated by createStepDiff
          namePosition: -1,
          valuePosition: -1,
        })) || [],
    };

    // Create a transaction to apply the step changes, using the same transaction
    // that may already have labware changes
    tr = createStepDiff({
      oldStep: currentStep,
      newStep,
      // It's okay to hard code this here for now.
      suggestionId: "fixAI",
      tr,
    });

    // Check if the transaction has any steps
    if (tr.steps.length === 0) {
      console.warn(
        "Transaction has no steps, nothing will change in the document"
      );
      return false;
    }

    // Dispatch the transaction
    view.dispatch(tr);

    return true;
  }

  /**
   * Updates AI generated IDs with real UUIDs in both newLabwareItems and fixedStep parameters
   *
   * @param aiResponse The original AI response
   * @returns Updated AI response with consistent UUIDs
   */
  function updateAIGeneratedIds(aiResponse: AIResponse): AIResponse {
    if (!aiResponse.newLabwareItems?.length) {
      return aiResponse;
    }

    // Create ID mapping from ai_gen IDs to real UUIDs
    const idMapping: Record<string, string> = {};

    // Generate new UUIDs for ai_gen IDs
    const updatedLabwareItems = aiResponse.newLabwareItems.map((item) => {
      if (item.id.startsWith("ai_gen")) {
        const newId = uuidv4();
        idMapping[item.id] = newId;
        return {
          ...item,
          id: newId,
        };
      }
      return item;
    });

    // Update references in fixedStep parameters
    let updatedFixedStep = { ...aiResponse.fixedStep };

    if (
      updatedFixedStep.parameters?.length &&
      Object.keys(idMapping).length > 0
    ) {
      updatedFixedStep.parameters = updatedFixedStep.parameters.map((param) => {
        // Check if the parameter value references any of the ai_gen IDs
        let updatedValue = param.value;

        Object.entries(idMapping).forEach(([oldId, newId]) => {
          // Replace all occurrences of the old ID with the new UUID
          updatedValue = updatedValue.replace(new RegExp(oldId, "g"), newId);
        });

        if (updatedValue !== param.value) {
          return {
            ...param,
            value: updatedValue,
          };
        }

        return param;
      });
    }

    return {
      ...aiResponse,
      newLabwareItems: updatedLabwareItems,
      fixedStep: updatedFixedStep,
    };
  }

  return {
    promptStatus,
    isFixingAll,
    currentFixProgress,
    totalFixCount,
    stepsBeingFixed,

    addStepBeingFixed,
    removeStepBeingFixed,
    setPromptStatus,
    fixSingleStep,
    fixAllErrors,
    applyAIFix,
  };
}
